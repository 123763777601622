import request from '@/utils/request'

// 初始化数据
export function getpageInit() {
    return request({
        url: "/api/supplier/pageInit",
        method: "get"
    })
}

// 新增供应商
export function addSupplier(data) {
    return request({
        url: "/api/supplier",
        data,
        method: "post"
    })
}

// 查询初始化
export function getListPage(params) {
    return request({
      url: "/api/supplier/listPage",
      params,
      method: "get"
    })
}

// 编辑供应商
export function editSupplier(data) {
   return request({
    url: "/api/supplier",
    data,
    method: "put" 
   })
}

// 删除供应商
export function delSupplier(id) {
    return request({
      url: "/api/supplier/" + id,
      method: "delete"
    })
}

// 导入供货商信息
export function ImportFile(data) {
    return request({
      url: "/api/supplier/addSupplierFile",
      data,
      method: "post" 
    })
}

// 供货商账号模块
// 新增
export function addSupplierAccount(data) {
   return request({
      url: "/api/supplierAccount",
      data,
      method: "post"
   })
}

// 根据id 删除
export function deleteSupplierAccount(id) {
    return request({
        url: "/api/supplierAccount/" + id,
        method: "delete"
    })
}

// 查询(不分页)
export function getListPage2(params) {
   return request({
       url: "/api/supplierAccount/list",
       params,
       method: "get"
   })
}

// 禁用-启用状态
export function updateAccountStatus(id) {
   return request({
     url: "/api/supplierAccount/updateAccountStatus/" + id,
     method: "put"
   })
}

// 查询(分页)
export function getListPage3(params) {
    return request({
      url: "/api/supplierAccount/listPage",
      params,
      method: "get" 
    })
} 

// 结算账号编辑
export function putSupplierAccount(data) {
   return request({
     url: "/api/supplierAccount",
     data,
     method: "put"
   })
}

// 设置默认账号
export function modifyAccountStatus(id) {
    return request({
      url: "/api/supplierAccount/updateDefaultAccount/" + id,
      method: "put"
    })
}

// 查询类别管理(结算方式)
export function getCategoryInit() {
  return request({
    url: "/api/supplier/categoryInit",
    method: "get"
  })
}

// 添加更新类别管理
export function addOrUpdateCategory(data) {
  return request({
    url: "/api/supplier/addOrUpdateCategory",
    data,
    method: "put"
  })
}

// 类别管理删除
export function deleteCategory(id) {
  return request({
    url: "/api/supplier/deleteCategory/" + id,
    method: "delete"
  })
}

// 附件上传
export function uploadFile(data) {
  return request({
    url: "/api/file/upload/uploadFile",
    data,
    method: 'post'
  })
}
